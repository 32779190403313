import React from "react";
import TextRevealByWord from "./TextRevealByWord";
import dorisAvatar from "../images/avatars/team/doris.png";
import jeremiahAvatar from "../images/avatars/team/jeremiah.png";
import marinaAvatar from "../images/avatars/team/marina.png";
import naveedAvatar from "../images/avatars/team/naveed.png";
import stefanAvatar from "../images/avatars/team/stefan.png";
import adamAvatar from "../images/avatars/team/adam.png";
import adamfAvatar from "../images/avatars/team/adamf.png";

const TeamIntroUXpilot = () => {

  const avatars = [
    { src: dorisAvatar, alt: "Doris" },
    { src: jeremiahAvatar, alt: "Jeremiah" },
    { src: marinaAvatar, alt: "Marina" },
    { src: adamAvatar, alt: "Adam" },
    { src: naveedAvatar, alt: "Naveed" },
    { src: stefanAvatar, alt: "Stefan" },
    { src: adamfAvatar, alt: "Adam F" },
  ];

  return (
    <>
      
      <div className="z-10 flex min-h-64 items-center justify-center rounded-lg bg-white dark:bg-black" 
        style={{ marginTop: '20rem' }}
      >
        <TextRevealByWord 
          text="We're the team behind UX Pilot — the AI startup for product Design." 
          avatars={avatars}  
          highlightWords={["UX", "Pilot"]}
        />
      </div>
    </>
  );
};

export default TeamIntroUXpilot;