import React, { useState, useEffect } from 'react';
import { Marquee } from './Marquee';

import captivate_1 from '../images/screens/captivate_1.png';
import devengage from '../images/screens/devengage.png';
import earac_1 from '../images/screens/earac_1.png';
import escend_1 from '../images/screens/escend_1.png';
import kaiburr_1 from '../images/screens/kaiburr_1.png';
import keyveve from '../images/screens/keyveve.png';
import keyveve_2 from '../images/screens/keyveve_2.png';
import kinteract_1 from '../images/screens/kinteract_1.png';
import limina from '../images/screens/limina.png';
import meshh from '../images/screens/meshh.png';
import mu_1 from '../images/screens/mu_1.png';
import mu_2 from '../images/screens/mu_2.png';
import oneup_1 from '../images/screens/oneup_1.png';
import scatterspoke_1 from '../images/screens/scatterspoke_1.png';
import startupos_1 from '../images/screens/startupos_1.png';
import sygma_1 from '../images/screens/sygma_1.png';
import sygma_2 from '../images/screens/sygma_2.png';
import tmobile_1 from '../images/screens/tmobile_1.png';
import tmobile_2 from '../images/screens/tmobile_2.png';
import uxpilot from '../images/screens/uxpilot.png';
import wti from '../images/screens/wti.png';

const screens = [
    { name: 'Escend', image: escend_1 },
    { name: 'Meshh', image: meshh },
    { name: 'Keyveve', image: keyveve },
    { name: 'WTI', image: wti },
    { name: 'DevEngage', image: devengage },
    { name: 'Limina', image: limina },
    { name: 'T-Mobile 1', image: tmobile_1 },
    { name: 'UX Pilot', image: uxpilot },
    { name: 'ScatterSpoke', image: scatterspoke_1 },
    { name: 'Sygma 1', image: sygma_1 },
    { name: 'Kaiburr', image: kaiburr_1 },
    { name: 'Kinteract', image: kinteract_1 },
    { name: 'OneUp', image: oneup_1 },
    { name: 'MU 1', image: mu_1 },
    { name: 'T-Mobile 2', image: tmobile_2 },
    { name: 'Captivate', image: captivate_1 },
    { name: 'StartupOS', image: startupos_1 },
    { name: 'Earac', image: earac_1 },
    { name: 'Keyveve 2', image: keyveve_2 },
    { name: 'MU 2', image: mu_2 },
    { name: 'Sygma 2', image: sygma_2 },
];

const ScreensRow = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);

    useEffect(() => {
        const imagePromises = screens.map(screen => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = screen.image;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(imagePromises)
            .then(() => setImagesLoaded(true))
            .catch(error => console.error('Error loading images:', error));
    }, []);
    
    const renderContent = () => {
        if (!imagesLoaded) {
            return screens.map((screen, index) => (
                <div
                    key={`skeleton-${index}`}
                    style={{
                        width: '200px',
                        height: '312px',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '8px',
                        margin: '0 12px',
                        animation: 'pulse 1.5s infinite ease-in-out',
                    }}
                />
            ));
        }

        return screens.map((screen) => (
            <img
                key={screen.name}
                src={screen.image}
                alt={screen.name}
                style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    border: '1px solid #e0e0e0',
                    borderRadius: '8px',
                    transition: 'transform 0.3s ease-in-out',
                    height: '312px',
                    width: 'auto',
                    objectFit: 'contain',
                    margin: '0 12px'
                }}
            />
        ));
    };

    return (
        <div className="col-xs-12 mt-0 mb-0 mb-md-2">
            <Marquee className="[--duration:80s]">
                {renderContent()}
            </Marquee>
            <style jsx>{`
                @keyframes pulse {
                    0% {
                        opacity: 0.6;
                    }
                    50% {
                        opacity: 0.8;
                    }
                    100% {
                        opacity: 0.6;
                    }
                }
            `}</style>
        </div>
    );
};

export default ScreensRow;


{/* 
    <InfiniteMovingCards
        items={screens}
        direction="left"
        speed="fast"
        pauseOnHover={false}
        imageStyle={{
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            border: '1px solid #e0e0e0',
            borderRadius: '8px',
            transition: 'transform 0.3s ease-in-out',
        }}
/> */}