import React from 'react';
import { InfiniteMovingCards } from './InfiniteMovingCards';

import SamsungLogo from '../images/logos/brand/grey-samsung.svg';
import TMobileLogo from '../images/logos/brand/grey-t-mobile.svg';
import KinteractLogo from '../images/logos/brand/grey-kinteract.svg';
import ThinkpricingLogo from '../images/logos/brand/grey-thinkpricing.svg';
import LaclaryLogo from '../images/logos/brand/laclary.svg';
import Qolo from '../images/logos/brand/qolo.svg';
import ActivePrime from '../images/logos/brand/activeprime.svg';
import Scatterspoke from '../images/logos/brand/scatterspoke.svg';
import Meshh from '../images/logos/brand/meshh.svg';

const brands = [
    { name: 'Samsung', logo: SamsungLogo },
    { name: 'Kinteract', logo: KinteractLogo },
    { name: 'Thinkpricing', logo: ThinkpricingLogo },
    { name: 'Laclary', logo: LaclaryLogo },
    { name: 'Qolo', logo: Qolo },
    { name: 'ActivePrime', logo: ActivePrime },
    { name: 'T-Mobile', logo: TMobileLogo },
    { name: 'Scatterspoke', logo: Scatterspoke },
    { name: 'Meshh', logo: Meshh },
  ];

const BrandsRow = () => {
    return (
        <div className="col-xs-12 mt-0 mb-4">
        <InfiniteMovingCards
            items={brands}
            direction="right"
            speed="slow"
            pauseOnHover={false}
        />
        </div>
    );
};

export default BrandsRow;