import React, { useEffect, useState } from "react";

export const InfiniteMovingCards = ({
  items,
  direction = "left",
  speed = "fast",
  pauseOnHover = false,
  className,
  imageStyle
}) => {
  const containerRef = React.useRef(null);
  const scrollerRef = React.useRef(null);
  

  useEffect(() => {
    addAnimation();
  }, []);

  const [start, setStart] = useState(false);
  const [isHovered, setIsHovered] = useState(false);


  function addAnimation() {
    if (containerRef.current && scrollerRef.current) {
      const scrollerContent = Array.from(scrollerRef.current.children);

      // Clone the items twice
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        scrollerRef.current.appendChild(duplicatedItem);
      });
      scrollerContent.forEach((item) => {
        const duplicatedItem = item.cloneNode(true);
        scrollerRef.current.appendChild(duplicatedItem);
      });

      getDirection();
      getSpeed();
      setStart(true);
    }
  }

  const getDirection = () => {
    if (containerRef.current) {
      if (direction === "left") {
        containerRef.current.style.setProperty("--animation-direction", "forwards");
      } else {
        containerRef.current.style.setProperty("--animation-direction", "reverse");
      }
    }
  };

  const getSpeed = () => {
    if (containerRef.current) {
      if (speed === "fast") {
        containerRef.current.style.setProperty("--animation-duration", "40s");
      } else if (speed === "normal") {
        containerRef.current.style.setProperty("--animation-duration", "80s");
      } else if (speed === "slow") {
        containerRef.current.style.setProperty("--animation-duration", "160s");
      } else if (speed === "slow-xl") {
        containerRef.current.style.setProperty("--animation-duration", "320s");
      } else if (speed === "slow-2xl") {
        containerRef.current.style.setProperty("--animation-duration", "640s");
      } else if (speed === "super-3xl") {
        containerRef.current.style.setProperty("--animation-duration", "1280s");
      } else if (speed === "super-4xl") {
        containerRef.current.style.setProperty("--animation-duration", "2560s");
      }else {
        containerRef.current.style.setProperty("--animation-duration", "160s");
      }
    }
  };

  const handleMouseEnter = () => {
    if (pauseOnHover) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    if (pauseOnHover) {
      setIsHovered(false);
    }
  };

  return (
    <div
      ref={containerRef}
      className={`scroller relative z-2 overflow-hidden ${className}`}
      style={{
        maskImage: 'linear-gradient(to right, transparent, white 5%, white 95%, transparent)'
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      >
      <ul
        ref={scrollerRef}
        className={`list-inline d-flex flex-nowrap py-2 ${start ? 'scroll' : ''} ${pauseOnHover ? 'hover-pause' : ''}`}
        style={{ 
          minWidth: '100%', 
          gap: '12px', 
          animationPlayState: isHovered ? 'paused' : 'running',
          width: 'fit-content'
          }}>
        {items.map((item, idx) => (
          <li
            key={`${item.name}-${idx}`}
            className="list-inline-item flex-shrink-0 d-flex align-items-center justify-content-center"
            style={{ width: 'auto' }}
          >
            {item.logo && (
              <item.logo
                alt={item.name}
                className="img-responsive mx-3"
                style={{ height: '28px', width: 'auto', objectFit: 'contain' }}
              />
            )}
            {item.image && (
              <img
                src={item.image}
                alt={item.name}
                className="img-responsive"
                style={{
                  ...imageStyle,
                  height: '312px',
                  width: 'auto',
                  objectFit: 'contain',
                  transition: 'transform 0.3s ease-in-out'
                }}
                onMouseEnter={(e) => {
                  e.currentTarget.style.transform = 'scale(1.05)';
                }}
                onMouseLeave={(e) => {
                  e.currentTarget.style.transform = 'scale(1)';
                }}
              />
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};