"use client";

import React, { useRef, useState, useEffect } from "react";
import { motion } from "framer-motion";

const useScroll = (ref) => {
  const [scrollYProgress, setScrollYProgress] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      if (!ref.current) return;

      const element = ref.current;
      const rect = element.getBoundingClientRect();
      const windowHeight = window.innerHeight;

      const start = windowHeight;
      const end = rect.height - rect.height * 0.5;

      const scrollPosition = windowHeight - rect.top;
      const progress = Math.max(0, Math.min(scrollPosition / (start + end), 1));

      setScrollYProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial call

    return () => window.removeEventListener('scroll', handleScroll);
  }, [ref]);

  return { scrollYProgress };
};

const TextRevealByWord = ({ text, className, avatars, highlightWords }) => {
  const containerRef = useRef(null);
  const { scrollYProgress } = useScroll(containerRef);
  const words = text.split(" ");

  return (
    <div 
      ref={containerRef} 
      style={{ 
        position: 'relative', 
        zIndex: 0, 
        height: '200vh', 
      }} 
      className={className}
    >
      <div
        style={{
          position: 'sticky',
          top: '50%',
          transform: 'translateY(-50%)',
          margin: '0 auto',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          background: 'transparent',
          padding: '1rem'
        }}
        className="text-reveal"
      >
        <div className="d-flex flex-row w-full justify-content-center align-items-center text-center mb-2">
          {avatars.map((avatar, index) => (
            <img
              key={index}
              src={avatar.src}
              alt={avatar.alt}
              className="avatar-image img-circle img-responsive center-block"
              style={{
                display: 'inline-block',
                border: '2px solid #fff',
                borderRadius: '16px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              }}
            />
          ))}
        </div>
        <p
          className="display-4"
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            padding: '5px',

          }}
        >
          {words.map((word, i) => {
            const start = i / words.length;
            const end = start + 1 / words.length;
            const isHighlighted = highlightWords.includes(word);
            return (
              <Word 
                key={i} 
                progress={scrollYProgress} 
                range={[start, end]}
                isHighlighted={isHighlighted}
              >
                {word}
              </Word>
            );
          })}
        </p>
      </div>
    </div>
  );
};

const Word = ({ children, progress, range, isHighlighted }) => {
  const [start, end] = range;
  const opacity = Math.min(Math.max((progress - start) / (end - start), 0), 1);
  return (
    <span style={{ position: 'relative', margin: '0 5px' }}>
      <span style={{ position: 'absolute', opacity: 0.1 }}>{children}</span>
      <motion.span
        style={{ 
          opacity: opacity, 
          color: isHighlighted ? '#3F20FB' : 'inherit'
        }}
      > 
        {children}
      </motion.span>
    </span>
  );
};

export default TextRevealByWord;