import React from 'react';
import { Link } from 'gatsby';
import DecorationBlob18 from '../images/decorations/deco-blob-18.svg';

const DatoAsset = ({ src, alt, className, style, lazy = true }) => (
    <picture>
      <source
        type="image/webp"
        srcSet={`${src}?fm=webp&w=200 200w,
  ${src}?fm=webp&w=400 400w,
  ${src}?fm=webp&w=800 800w,
  ${src}?fm=webp&w=1200 1200w,
  ${src}?fm=webp&w=1436 1436w`}
        sizes="(max-width: 800px) 100vw, 800px"
      />
      <source
        srcSet={`${src}?w=200 200w,
  ${src}?w=400 400w,
  ${src}?w=800 800w,
  ${src}?w=1200 1200w,
  ${src}?w=1436 1436w`}
        sizes="(max-width: 800px) 100vw, 800px"
      />
      <img
        sizes="(max-width: 800px) 100vw, 800px"
        srcSet={`${src}?fm=webp&w=200 200w,
  ${src}?w=400 400w,
  ${src}?w=800 800w,
  ${src}?w=1200 1200w,
  ${src}?w=1436 1436w`}
        src={`${src}?w=1200`}
        alt={alt || ''}
        className={className || ''}
        style={style || {}}
        loading={lazy ? 'lazy' : 'eager'}
      />
    </picture>
  );

const DesignProcess = ({ data }) => {
  const processSteps = [
    {
      phase: 'Phase 1',
      title: 'UX Audit',
      description: 'Full review of your product, your business and user goals',
      image: 'https://www.datocms-assets.com/16499/1591812679-audit.jpg',
      link: 'https://adamfard.com/ux-design-process#audit'
    },
    {
      phase: 'Phase 2',
      title: 'Research',
      description: 'Deep dive into your niche, target users and competitors',
      image: 'https://www.datocms-assets.com/16499/1591812688-research.jpg',
      link: 'https://adamfard.com/ux-design-process#research'
    },
    {
      phase: 'Phase 3',
      title: 'UX Strategy',
      description: 'Foolproof data-driven strategy for your product',
      image: 'https://www.datocms-assets.com/16499/1591812691-strategy.jpg',
      link: 'https://adamfard.com/ux-design-process#UX-strategy'
    },
    {
      phase: 'Phase 4',
      title: 'UX Conception',
      description: 'Design concepts to turn your product vision to life',
      image: 'https://www.datocms-assets.com/16499/1591812683-conception.jpg',
      link: 'https://adamfard.com/ux-design-process#UX-conception'
    },
    {
      phase: 'Phase 5',
      title: 'Visual and UI Design',
      description: 'Develop scalable and consistent design interfaces',
      image: 'https://www.datocms-assets.com/16499/1591812784-ui.jpg',
      link: 'https://adamfard.com/ux-design-process#UI-design'
    },
    {
      phase: 'Phase 6',
      title: 'QA & Analysis',
      description: 'Measure results, learn and improve after product launch',
      image: 'https://www.datocms-assets.com/16499/1591812686-qa.jpg',
      link: 'https://adamfard.com/ux-design-process#QA-analysis'
    }
  ];

  return (
    <section className="o-hidden mt-0 mt-md-3 py-5 third-section">
      <div className="decoration-wrapper">
        <div className="decoration right" style={{ top: -320, right: -70 }}>
          <DecorationBlob18
            className="bg-neutral-warm"
            style={{ transform: 'scale(0.92)' }}
          />
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-6">
            <h2 className="display-4">
              Here's how we make <br /> products grow
            </h2>
            <p className="col-xl-7 col-lg-8 col-md-10 mx-auto lead">
              Our UX design process
            </p>
          </div>
        </div>

        <div className="row mx-0 mx-lg-5 pb-0 pb-md-7 justify-content-center">
          {processSteps.map((step, index) => (
            <div key={index} className="col-12 col-md-6 col-lg-4">
              <Link to={step.link} target="_blank">
                <div className="card card--common card--blog--vertical card-body text-center">
                  <DatoAsset
                    src={step.image}
                    alt={`Chapter ${index + 1}`}
                  />
                  <p className="mb-0 mt-1 process-label">{step.phase}</p>
                  <h4 className="mb-0 mt-1">{step.title}</h4>
                  <p className="process-description mt-1">
                    {step.description}
                  </p>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default DesignProcess;
