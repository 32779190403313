import React from 'react';

export function Marquee({
  className,
  reverse,
  pauseOnHover = false,
  children,
  vertical = false,
  repeat = 4,
  ...props
}) {
  const containerClasses = [
    'marquee-container',
    'd-flex',
    'overflow-hidden',
    'p-2',
    vertical ? 'flex-column' : 'flex-row',
    className
  ].filter(Boolean).join(' ');

  const itemClasses = [
    'marquee-item',
    'd-flex',
    'flex-shrink-0',
    'justify-content-around',
    vertical ? 'flex-column' : 'flex-row',
    reverse ? 'marquee-reverse' : '',
    pauseOnHover ? 'marquee-pause-on-hover' : ''
  ].filter(Boolean).join(' ');

  return (
    <div
      {...props}
      className={containerClasses}
      style={{
        '--duration': '80s',
        '--gap': '1rem',
        gap: 'var(--gap)'
      }}
    >
      {Array(repeat).fill(0).map((_, i) => (
        <div key={i} className={itemClasses}>
          {children}
        </div>
      ))}
    </div>
  );
}
